import {
  Spacer,
  Tooltip,
  Badge,
  Button,
  Card,
  CardBody,
  Spinner,
} from "@nextui-org/react";
import { toast } from "sonner";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../services/User.service";
import copyLogo from "../../svgs/copy.svg";
import { Helmet } from "react-helmet";
import Notification from "../common/Notification";
import noMessageIcon from "../../svgs/noMessage.svg";

const copyText = (text: string) => {
  copy(text);
  toast.info("Copied to clipboard.");
};

const SMSItem = ({
  text,
  date,
  from,
}: {
  text: string;
  date: number;
  from: string;
}) => {
  let otp = "";

  const hasOTP = () => {
    const otpRegex = /((\d[\d\s-]*){3,8})/g;

    const isHasOTP = otpRegex.test(text);

    if (isHasOTP) {
      const matches = text.match(otpRegex);

      const res = matches ? matches[0].replace(/[\s-]+/g, "") : "";

      otp = res;
    }

    return isHasOTP;
  };

  return (
    <div className="grid gap-4">
      <Helmet>
        <title>Get SMS</title>
      </Helmet>
      <div className="w-full">
        <div className="flex justify-end w-full">
          <p>from: {from}</p>
        </div>
        <Card className="bg-primary text-white">
          <CardBody onClick={() => copyText(text)}>{text}</CardBody>
        </Card>
      </div>

      <div className="flex flex-row justify-between">
        <div>
          <p className="text-black">
            {new Date(date).toLocaleDateString() +
              " " +
              new Date(date).toLocaleTimeString()}
          </p>
        </div>

        <div>
          {hasOTP() && (
            <Button
              variant="bordered"
              color="primary"
              onClick={() => copyText(otp)}
            >
              {otp}
              <img src={copyLogo} alt="Copy icon" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default function SMS() {
  const [userMessages, setUserMessages] = useState<
    {
      text: string;
      date: number;
      from: string;
      otp?: string;
    }[]
  >([]);
  const [isInactiveUser, setIsInactiveUser] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [isProcessingNumber, setIsProcessingNumber] = useState(false);

  const useUserService = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const getSms = async () => {
      const { messages, phoneNumber, isInactive, isProcessing } =
        await useUserService.getMessages(); // TODO: move to a separate API

      if (!phoneNumber && !isProcessing) {
        navigate("/buy-number");
      }

      setIsProcessingNumber(isProcessing);
      setIsInactiveUser(isInactive);
      setUserMessages(messages);
      setUserPhoneNumber(phoneNumber);
    };

    getSms();
    const getMessageInterval = setInterval(() => getSms(), 5000);

    return () => clearInterval(getMessageInterval);
  }, []);

  return (
    <>
      <div className="p-3">
        <div className="flex flex-row justify-between flex-wrap">
          {userPhoneNumber && (
            <div className="flex">
              <b className="text-large ">Your phone number is:</b>
              <Spacer x={3} />
              <Tooltip content="Copy">
                <Badge
                  size="lg"
                  showOutline={false}
                  placement="top-right"
                  //content={userPhoneNumber.flag}
                >
                  <Button
                    color="primary"
                    size="md"
                    variant="bordered"
                    onClick={() => copyText(userPhoneNumber)}
                  >
                    {userPhoneNumber}
                  </Button>
                </Badge>
              </Tooltip>
            </div>
          )}
          {/* <TestButton type="affiliate" /> */}
        </div>
      </div>

      <div className="p-3">
        {isInactiveUser && <Notification type="failedPayment" />}

        {isProcessingNumber ? (
          <p className="text-3xl flex items-center justify-center">
            <span>
              <Spinner color="primary" labelColor="primary" />
              We are processing your number, it will be ready soon.
            </span>
          </p>
        ) : userMessages.length ? (
          userMessages.map((message, index) => (
            <div className="pt-3" key={index}>
              <SMSItem
                text={message.text}
                date={message.date}
                from={message.from}
              />
            </div>
          ))
        ) : (
          <p className="text-3xl flex items-center justify-center">
            <img src={noMessageIcon} alt="" />
            <span>No messages yet</span>
          </p>
        )}
      </div>
    </>
  );
}

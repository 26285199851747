import { Button } from "@nextui-org/react";
import googleIcon from "../svgs/google.svg"

export function GoogleLogin() {
  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_SERVER_PATH}/auth/google`;
  };

  return (
    <Button
      onClick={handleLogin}
      size="lg"
      fullWidth={true}
      variant="bordered"
      className="border-gray-900"
    >
    <img src={googleIcon} alt="Google icon" />  Continue with Google
    </Button>
  );
}

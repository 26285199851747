import { Helmet } from "react-helmet";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Link,
} from "@nextui-org/react";
import { Register } from "./Register";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../services/User.service";
import { usePrice } from "../../services/usePrice";
import { getPrice } from "../../helpers/getPrice";
import { FAQ } from "../common/FAQ";

export default function Landing() {
  const navigate = useNavigate();
  const { user, getToken, initUser, googleLogin } = useUser();
  const [secondLoad, setSecondLoad] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);

  const accessToken = queryParams.get("accessToken") || "";
  const promo = queryParams.get("promo") || "";

  const [price] = usePrice(promo);

  useEffect(() => {
    const login = async () => {
      if (!accessToken) {
        return;
      }

      await googleLogin(accessToken);

      navigate("/sms");
    };
    login();
  }, []);

  useEffect(() => {
    const call = async () => {
      if (getToken()) {
        await initUser();
      }

      return setSecondLoad(true);
    };

    call();
  }, []);

  useEffect(() => {
    if (!secondLoad || !user) {
      // reverse logic of authroute
      return;
    }

    navigate("/sms");
  }, [user, secondLoad]);

  const initialCompetitorPrice = 36;
  const specialOffer = getPrice(price);
  const prevSpecialOffer = getPrice(Math.ceil(price * 2));
  const competitorPrice = getPrice(initialCompetitorPrice);
  const savedMoney = getPrice(Math.round(initialCompetitorPrice - price));

  const pricePerMonth = getPrice(price);

  return (
    <>
      {/* header section */}
      <header className="flex min-h-screen">
        <div className="col-md justify-center flex flex-col md:w-1/2 w-full">
          <h1 className="text-3xl">
            <p>
              GetSMS: Receive <b>SMS/OTP</b> with a simple flat monthly fee.
            </p>
          </h1>
          <h2 className="mt-4 text-2xl">
            Rent a virtual pone number and Receive SMS from anywhere in the world. Reserve Phone Number in US
            🇺🇸. Allow your business go international.
          </h2>
          <h3 className="mt-4 text-3xl">
            <s>{prevSpecialOffer}</s> <b className="text-5xl">{specialOffer}</b>
            <span className=""> Special offer </span>
          </h3>
          <Button
            as={Link}
            size="lg"
            href="/register"
            color="primary"
            className="mt-4 bg-gradient-to-tr from-blue-700 to-blue-400 text-3xl p-5 font-bold"
          >
            💫 REGISTER NOW
          </Button>
          <h1 className="text-2xl mt-16">
            We have an even better deal, get for <b className="text-4xl">{getPrice(1.99)}</b> a month by filling the form.
          </h1>
          <Button
            as={Link}
            size="lg"
            href="https://docs.google.com/forms/d/e/1FAIpQLSclx5JmtP8H8mba2IrSDx4hG-gQwJYbRH2Hfi_ZsVcKls3gMQ/viewform?usp=sf_link"
            color="primary"
            className="mt-4 bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg text-2xl"
          >
            💸 Fill the form, get a discount
          </Button>
        </div>
        <div className="col-md hidden md:flex md:w-1/2">
          <div className="ml-5 w-full mt-10 p-1.5 rounded-md animated-border bg-white bg-gradient-to-br from-cyan-300  via-blue-500 to-purple-500 bg-[length:300%_300%] flex justify-center items-center">
            <img
              className="floating"
              style={{maxHeight:"80vh"}}
              src="/images/screenshot.png"
              alt="telegram logo"
            />
          </div>
        </div>
      </header>
      {/* Second section */}
      <div className="gap-5 grid grid-cols-12 mt-24">
        <div className="md:col-span-6 col-span-12">
          <div className="mt-10 p-1.5 rounded-md animated-border bg-white bg-gradient-to-br from-cyan-300  via-blue-500 to-purple-500 bg-[length:300%_300%] flex justify-center items-center">
            <img
              src="/images/screenshot.png"
              alt=""
              className="md:w-60 w-full"
            />
          </div>
        </div>
        <div className="md:col-span-6 col-span-12 flex justify-center items-center gap-5">
          <Card className="mt-10 p-5">
            <h1 className="text-5xl">
              We want to receive SMS and we want it NOW!
            </h1>
            <p className="text-xl">
              No matter where you are in the world, our service allows you to
              effortlessly receive SMS messages from anywhere. Say goodbye to
              location restrictions and never miss an important message again,
              whether you're traveling, working remotely, or simply on the go.
              Our global reach ensures that you stay connected no matter where
              life takes you.
            </p>
          </Card>
        </div>
      </div>
      {/* Third section */}
      <div className="w-full mt-24">
        <div className="gap-5 grid grid-cols-2">
          <div className="col-span-2">
            <h1 className="text-7xl">Pricing is beneficiary</h1>
            <p className="text-xl mt-10">
              At GetSMS, simplicity is key – that's why we offer just one
              straightforward pricing plan. With no confusing tiers or hidden
              costs, you'll always know exactly what you're getting with GetSMS.
            </p>
          </div>
          <div className="col-span-2 sm:col-span-1">
            <div className="mt-24">
              <p className="text-5xl">
                <u>Save {savedMoney} with us</u>
              </p>
              <h4 className="text-black font-medium text-3xl mt-4">
                Our Competitors offer for renting a number is {competitorPrice}
                /month
              </h4>
            </div>
          </div>
          <Card className=" col-span-2 sm:col-span-1 p-1.5 animated-border bg-white bg-gradient-to-br from-cyan-300  via-blue-500 to-purple-500 bg-[length:300%_300%]">
            <Card isFooterBlurred className="w-full h-[250px]">
              <CardHeader className="absolute z-10 top-1 flex-col items-start"></CardHeader>
              <CardFooter className="absolute bg-white/30 bottom-0 border-t-1 border-zinc-100/50 z-10 justify-between">
                <div>
                  <p className="text-black text-3xl">
                    Pay as long as you want, cancel anytime.
                  </p>
                  <p className="text-black text-5xl">{pricePerMonth} /month</p>
                </div>
                <Button
                  color="primary"
                  radius="full"
                  size="lg"
                  as={Link}
                  href="/register"
                >
                  Join now
                </Button>
              </CardFooter>
            </Card>
          </Card>
        </div>
      </div>
      {/* Second section 2 */}
      <div className="gap-5 grid grid-cols-2 mt-24">
        <Card className="col-span-2 sm:col-span-1 p-10 flex justify-center items-center gap-5">
          <h1 className="text-5xl">Ensuring Your Security: Our Top Priority</h1>
          <p className="text-xl mt-10">
            Our service prioritizes both security and anonymity, ensuring your
            peace of mind with every SMS received. We employ robust security
            measures to safeguard your data and communication, while also
            respecting your privacy by maintaining anonymity throughout the
            process. With us, you can trust that your messages are secure and
            your identity remains protected.{" "}
          </p>
          <Button
            as={Link}
            size="lg"
            href="/register"
            color="primary"
            className="mt-4 bg-gradient-to-tr from-blue-700 to-blue-400 text-3xl p-5 font-bold w-full"
          >
            JOIN
          </Button>
        </Card>
        <div className="col-span-2 sm:col-span-1 flex justify-center items-center">
          <img src="/images/lock.webp" alt="" />
        </div>
      </div>

      {/*FAQ */}
      <div className="mt-24">
        <FAQ></FAQ>
      </div>
      {/**Register section*/}
      <div className="mt-24">
        <h1 className="text-5xl">Join us!</h1>
        <Card className="p-10 mt-10">
          <Register></Register>
        </Card>
      </div>

      <Helmet>
        <title>Simplest way to get SMS securely</title>
      </Helmet>
    </>
  );
}

import { paymentFailed } from "../../consts/paymentFailText";

export enum ELoginQueries {
  "resetPassword" = "resetPassword",
  "mailConfirmation" = "mailConfirmation",
}

export type ILoginNotices = "mailConfirmation" | "resetPassword";
interface INotificationProps {
  type: "failedPayment" | ILoginNotices;
}

const notifications = {
  failedPayment: {
    className: "text-danger-500",
    text: paymentFailed,
  },
  mailConfirmation: {
    className: "",
    text: "Email confirmed. Please login to access your account.",
  },
  resetPassword: {
    className: "",
    text: "Password reset was successful. Login to access your account",
  },
};

export default function Notification({ type }: INotificationProps) {
  const notification = notifications[type] || { className: "", text: "" };
  const { className, text } = notification;

  return (
    <div className={`flex flex-row justify-center ${className}`}>{text}</div>
  );
}

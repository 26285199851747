import { Button, Link, Input } from "@nextui-org/react";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../services/User.service";
import { Helmet } from "react-helmet";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const useUserService = useUser();
  const [errors, setErrors] = useState({ email: "" });

  const [email, setEmail] = useState("");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await useUserService.resetPassword({
        email,
      });

      if (response.errors) {
        return setErrors(response.errors);
      }

      setErrors({ email: "" });
      navigate("/recover-password")
    } catch (err) {
      alert(err);
    }
  };

  return (
    <form className="flex w-full flex-wrap gap-4" onSubmit={handleSubmit}>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <h1 className="text-4xl">Forgot Password?</h1>
      <Input
        type="email"
        label="Email"
        radius="sm"
        size="lg"
        placeholder="youremail@email.com"
        onChange={(e: any) => {
          setErrors({ ...errors, email: "" });
          setEmail(e.target.value);
        }}
        isInvalid={!!errors.email}
        errorMessage={errors.email}
      />
      <Button color="primary" size="lg" fullWidth={true} type="submit">
        Request password reset
      </Button>
      <Link href="/register">Don't have an account? Register now!</Link>
      <Link href="/login">Login</Link>
    </form>
  );
};

import checkmark from "../svgs/checkmark.svg";
import warning from "../svgs/warning.svg";
import envelope from "../svgs/envelope.svg";
import password from "../svgs/password.svg";
export interface INotificationsDataType {
  text: string;
  icon: any;
  hasButton: boolean;
  buttonColor: "primary" | "success";
  buttonText: string;
  title: string;
  href: string;
}
export type INotificationsDataKeys =
  | "mailConfirmation"
  | "recoverPassword"
  | "successPayment"
  | "failedPayment"
  | "accountDeleted";

export const notificationsData: Record<
  INotificationsDataKeys,
  INotificationsDataType
> = {
  mailConfirmation: {
    text: "Thank you for registering on our website! To complete the process please check your email inbox and confirm your email address.",
    icon: envelope,
    hasButton: false,
    buttonColor: "primary",
    buttonText: "",
    title: "Mail Confirmation",
    href: "",
  },
  recoverPassword: {
    text: "Please check your email to reset your password.",
    icon: password,
    hasButton: true,
    buttonColor: "primary",
    buttonText: "Login",
    title: "Recover Password",
    href: "/login",
  },
  successPayment: {
    text: "Payment confirmed! Thank you for your purchase.",
    icon: checkmark,
    hasButton: true,
    buttonColor: "primary",
    buttonText: "To the SMS page",
    title: "Thank You!",
    href: "/sms",
  },
  failedPayment: {
    text: " We’re sorry, but it looks like there was a problem processing your payment. Please check your payment details and try again.",
    icon: warning,
    hasButton: true,
    buttonColor: "success",
    buttonText: "Try again",
    title: "Failed Payment",
    href: "/buy-number",
  },
  accountDeleted: {
    text: "Your account has been deleted. We appreciate your time with us and hope to see you again in the future!",
    icon: "",
    buttonColor: "primary",
    buttonText: "",
    hasButton: false,
    href: "",
    title: "Account deleted",
  },
};

export type ITestButtonsDataKeys = "affiliate" | "mobileApp" | "notification";

interface ITestButtonsData {
  text: string;
  event: string;
  localStorageKey: string;
}

export const testButtonsData: Record<ITestButtonsDataKeys, ITestButtonsData> = {
  affiliate: {
    text: "💫 1 month free for each referred friend",
    event: "affiliate-link-clicked",
    localStorageKey: "hasClickedAffiliateLink",
  },
  mobileApp: {
    text: "📱 Download Mobile App",
    event: "mobile-app-link-clicked",
    localStorageKey: "hasClickedMobileAppLink",
  },
  notification: {
    text: "🔔",
    event: "notification-link-clicked",
    localStorageKey: "hasClickedNotificationLink",
  },
};

import { Button, Card, CardHeader, Input } from "@nextui-org/react";
import { FormEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useUser } from "../../services/User.service";
import { Helmet } from "react-helmet";
import { ELoginQueries } from "../common/Notification";

export const RecoverPassword = () => {
  const { resetPasswordId } = useParams();
  const [errors, setErrors] = useState({ message: "" });
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const useUserService = useUser();

  if (!resetPasswordId) {
    return null;
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await useUserService.newPassword({
        password,
        resetPasswordId,
      });
      if (response.errors) {
        return setErrors(response.errors);
      }
      setErrors({ message: "" });
      navigate(`/login?redirectFrom=${ELoginQueries.resetPassword}`);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <form className="flex w-full flex-wrap gap-4" onSubmit={handleSubmit}>
      <Helmet>
        <title>Recover Password</title>
      </Helmet>
      <h1 className="text-4xl">Recover Password</h1>
      <div className="w-full">
        <p>Type in your new password below.</p>
      </div>
      <Card className="w-full mb-4">
        <CardHeader>
          <Input
            type="password"
            label="New password"
            radius="sm"
            size="lg"
            placeholder="new password"
            onChange={(e: any) => {
              setErrors({ ...errors, message: "" });
              setPassword(e.target.value);
            }}
            isInvalid={!!errors.message}
            errorMessage={errors.message}
          />
        </CardHeader>
        <Button color="primary" size="lg" fullWidth={true} type="submit">
          Request password reset
        </Button>
      </Card>
    </form>
  );
};

import { Helmet } from "react-helmet";
import React from "react";
export const NoPage = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <h1 className="text-7xl text-center">What? Page not found! 🐩</h1>
    </>
  );
};

import { useContext, useEffect } from "react";
import { useLocalStorage } from "./useLocalStorage";
import * as UserAPI from "../api/User.api";
import { AuthContext } from "./AuthContext";

export interface ICountry {
  name: string;
  isoCountry: string;
  emoji: string;
  local?: boolean;
}

export interface IUser {
  email: string;
  country: ICountry;
}

export interface IRegisteringUser {
  email: string;
  password: string;
  country: string;
}

export interface ILoginUser {
  email: string;
  password: string;
}

export interface IEmailUser {
  email: string;
}

const tokenLocalstorageKey = "token";

export const useUser = () => {
  const { user, setUser } = useContext(AuthContext);
  const { setItem, getItem, removeItem } = useLocalStorage();

  const addUser = (user: IUser) => {
    setUser(user);
  };

  const setToken = (token: string) => {
    setItem(tokenLocalstorageKey, token);
  };

  const getToken = () => {
    return getItem(tokenLocalstorageKey);
  };

  const removeUser = () => {
    setUser(null);
    setToken("");
  };

  useEffect(() => {
    const user = getItem("user");
    if (user) {
      addUser(JSON.parse(user));
    }
  }, []);

  const initUser = async () => {
    const token = getToken();
    const userMe = (await UserAPI.getMe(token as string)) as IUser;
    addUser(userMe);
    return userMe;
  };

  const getMe = async () => {
    const token = getToken();

    return await UserAPI.getMe(token as string);
  };

  const loginUser = async (user: ILoginUser) => {
    const response = await UserAPI.login(user);
    if (!response.token) {
      return response;
    }

    setToken(response.token);
    return await initUser();
  };

  const confirmEmail = async (mailConfirmationId: string) => {
    return UserAPI.confirmEmail({ mailConfirmationId });
  };

  const resetPassword = async (user: IEmailUser) => {
    setToken("");
    setUser(null);
    return await UserAPI.resetPassword(user);
  };

  const newPassword = async ({
    password,
    resetPasswordId,
  }: {
    password: string;
    resetPasswordId: string;
  }) => {
    setToken("");
    return UserAPI.newPassword({ password, resetPasswordId });
  };

  const registerUser = async (user: IRegisteringUser) => {
    return await UserAPI.register(user);
  };

  const getCountries = async () => {
    return await UserAPI.getCountries();
  };
  const getMessages = async () => {
    const token = getToken();
    const messages = await UserAPI.messages(token as string);
    return messages;
  };

  const getSessionUrl = async (phoneNumber: string, testPriceKey: string) => {
    const token = getToken();

    return UserAPI.getSessionUrl(token as string, phoneNumber, testPriceKey);
  };

  const getPhoneNumberCountriesList = async () => {
    return UserAPI.getPhoneNumberCountriesList();
  };

  const getNumbersByCountry = async () => {
    return UserAPI.getNumbersByCountry();
  };

  const cancelSubscription = async () => {
    const token = getToken();

    return UserAPI.cancelSubscription(token as string);
  };

  const deleteAccount = async () => {
    const token = getToken();
    removeItem(tokenLocalstorageKey);

    return UserAPI.deleteAccount(token as string);
  };

  const changeExpectation = async (isExpectingEmail: boolean) => {
    const token = getToken();

    return UserAPI.changeExpectation(token as string, { isExpectingEmail });
  };

  const googleLogin = async (token: string) => {
    const res = await UserAPI.googleLogin(token as string);

    if (!res.token) {
      return res;
    }

    setToken(res.token);
    return await initUser();
  };

  return {
    user,
    addUser,
    getMe,
    removeUser,
    loginUser,
    confirmEmail,
    registerUser,
    resetPassword,
    getCountries,
    setUser,
    getToken,
    setToken,
    initUser,
    getMessages,
    getSessionUrl,
    newPassword,
    getPhoneNumberCountriesList,
    getNumbersByCountry,
    cancelSubscription,
    deleteAccount,
    changeExpectation,
    googleLogin,
  };
};

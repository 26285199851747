import { Button, Card, Link, Spacer, Spinner } from "@nextui-org/react";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";

import { useUser } from "../../services/User.service";
import CheckoutForm from "../common/SubscriptionButton";
import { usePrice } from "../../services/usePrice";
import { getPrice } from "../../helpers/getPrice";
import { FAQ } from "../common/FAQ";

export interface IPhoneNumber {
  phoneNumber: string;
  friendlyName: string;
}

export interface IPhoneNumberResponse {
  phoneNumbers: IPhoneNumber[];
  errorMessage?: string;
}

export const BuyNumber = () => {
  const [price] = usePrice();
  const [phoneNumbersResponse, setPhoneNumbersResponse] =
    useState<IPhoneNumberResponse>({
      phoneNumbers: [],
    });
  const [isLoading, setIsLoading] = useState(false);
  const useUserService = useUser();

  useEffect(() => {
    const getPhoneNumber = async () => {
      setIsLoading(true);
      const phoneNumberList = await useUserService.getNumbersByCountry();

      setPhoneNumbersResponse(phoneNumberList);
      setIsLoading(false);
    };

    getPhoneNumber();
  }, []);

  const pricePerMonth = getPrice(price);

  return (
    <>
      <Helmet>
        <title>Buy Number</title>
      </Helmet>
      <h2 className="text-3xl pl-4 mt-4">
        Please select your preferred phone number for <b>{pricePerMonth}</b>:
      </h2>
      <Spacer y={2} />
      <div className="w-full flex justify-evenly flex-wrap mt-2">
        <>
          {isLoading ? (
            <div className="flex justify-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <>
              {phoneNumbersResponse.errorMessage ||
              !phoneNumbersResponse?.phoneNumbers.length ? (
                <p>{phoneNumbersResponse.errorMessage}</p>
              ) : (
                <>
                  {phoneNumbersResponse?.phoneNumbers.map((phoneNumber) => {
                    return (
                      <div>
                        <CheckoutForm phoneNumber={phoneNumber} />
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </>
      </div>
      <Card className="mt-5 mb-5">
        <h1 className="text-2xl mt-16">
          We have an even better deal, get for <b>{getPrice(1.99)}</b> a month
          by filling the form.
        </h1>
        <Button
          as={Link}
          size="lg"
          href="https://docs.google.com/forms/d/e/1FAIpQLSclx5JmtP8H8mba2IrSDx4hG-gQwJYbRH2Hfi_ZsVcKls3gMQ/viewform?usp=sf_link"
          color="primary"
          className="mt-4 bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg text-2xl"
        >
          💸 Fill the form, get a discount
        </Button>
      </Card>
      <FAQ></FAQ>
    </>
  );
};

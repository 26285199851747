import { TestButton } from "../common/TestButton";

export const Footer = () => {
  const email = process.env.REACT_APP_CONTACT_EMAIL;

  return (
    <footer className="w-full mt-40 text-xs text-gray-500 text-center">
      {/* commented this because everyone was clicking on this button and it did't work as expected. */}
      {/* <TestButton type="mobileApp" /> */}

      <p className="flex justify-center mt-4">
        <a className="underline " href={`mailto:${email}`}>
          {email}
        </a>
      </p>
      <p className="text-xl">
        If you have suggestions for improvement or encounter any problems, glitches,
        please don't hesitate to{" "}
        <a className="underline" href={`mailto:${email}`}>
          contact us
        </a>
        . Your report will help us enhance the user experience for everyone.
      </p>
      <br />
      <p className="px-4">
        GetSMS is a platform designed to facilitate global text message
        communication by allowing users to purchase phone numbers from the US.
        This enables them to seamlessly receive text messages from anywhere in
        the world, irrespective of geographic boundaries. Each account is
        allowed to choose one unique number. With a user-friendly interface, our
        platform offers ease of navigation. Users can efficiently manage phone
        numbers and review message history. Our service simplifies the process
        of staying connected globally.
      </p>
      <p className="mt-2">
        lovepowered llc{" "}
        <a href="/privacy-policy" className="underline">
          Privacy Policy
        </a>
      </p>
    </footer>
  );
};

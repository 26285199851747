import { Button } from "@nextui-org/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useUser } from "../../services/User.service";
import { IPhoneNumber } from "../pages/BuyNumber";
import { usePrice } from "../../services/usePrice";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
);

const CheckoutForm = ({ phoneNumber }: { phoneNumber: IPhoneNumber }) => {
  const useUserService = useUser();
  const [_, testPriceKey] = usePrice();

  const handleClick = async () => {
    if (!phoneNumber) {
      return;
    }

    const { url } = await useUserService.getSessionUrl(
      phoneNumber.phoneNumber,
      testPriceKey
    );

    if (!url) {
      return;
    }

    window.location.href = url;
  };

  return (
    <Elements stripe={stripePromise}>
      <Button
        className="m-1 bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg"
        onClick={handleClick}
      >
        {phoneNumber.phoneNumber}
      </Button>
    </Elements>
  );
};

export default CheckoutForm;

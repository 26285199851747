import { Routes, Route, useNavigate } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import { Toaster } from "sonner";

import "./App.css";
import { AppNavbar } from "./components/appNavbar";
import { Register } from "./components/pages/Register";
import { Login } from "./components/pages/Login";
import { ForgotPassword } from "./components/pages/ForgotPassword";
import { RecoverPassword } from "./components/pages/RecoverPassword";
import { Settings } from "./components/pages/Settings";
import SMS from "./components/pages/sms";
import { NoPage } from "./components/pages/NoPage";
import { BuyNumber } from "./components/pages/BuyNumber";

import AuthRoute from "./providers/authroute.provider";
import UserProvider from "./providers/user.provider";
import Landing from "./components/pages/Landing";

import { MailConfirmation } from "./components/pages/MailConfirmation";
import { NotificationData } from "./components/pages/NotificationTemplate";
import { Footer } from "./components/sections/Footer";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";

function App() {
  const navigate = useNavigate();

  return (
    <NextUIProvider navigate={navigate}>
      <UserProvider>
        <AppNavbar></AppNavbar>
        {/* TODO: move image to local */}
        <div className="bg-[length:300px_300px] bg-[url('https://sms.powered-by.love/pattern.png')]"> 
          <div className="container mx-auto p-3">
            <Routes>
              <Route path="/">
                <Route index element={<Landing />}/>
                <Route path="/settings" element={<AuthRoute component={<Settings />} />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/sms" element={<AuthRoute component={<SMS />} />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/new-password/:resetPasswordId" element={<RecoverPassword />} />
                <Route path="/confirm-email/:mailConfirmationId" element={<MailConfirmation />} />
                <Route path="*" element={<NoPage />} />
                <Route path="/buy-number" element={<AuthRoute component={<BuyNumber />} />} />
                <Route path="/success" element={<AuthRoute component ={<NotificationData type={"successPayment"}/>}/>}/>
                <Route path="/fail" element={<AuthRoute component ={<NotificationData type={"failedPayment"}/>}/>}/>
                <Route path="/mail-confirmation" element={<NotificationData type={"mailConfirmation"} />} />
                <Route path="/recover-password" element={<NotificationData type={"recoverPassword"}/>}/>
                <Route path="/deleted-account" element={<NotificationData type={"accountDeleted"} />} />
              </Route>
            </Routes>
          </div>
          <Footer />
        </div>
      </UserProvider>
      <Toaster position="top-center" richColors />
    </NextUIProvider>
  );
}

export default App;

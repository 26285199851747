import {
  Card,
  Link,
  CardBody,
  Divider,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
  Checkbox,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../services/User.service";

export const Settings = () => {
  const [isSelected, setIsSelected] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [deleteOrCancel, setDeleteOrCancel] = useState("delete");

  const navigate = useNavigate();
  const useUserService = useUser();

  useEffect(() => {
    const getMe = async () => {
      const { isExpectingEmail } = await useUserService.getMe();

      setIsSelected(isExpectingEmail);
    };

    getMe();
  });

  const cancelSubscription = async () => {
    try {
      await useUserService.cancelSubscription();
      navigate("/buy-number");
    } catch (err) {
      alert(err);
    }
  };

  const deleteAccount = async () => {
    try {
      await useUserService.deleteAccount();
      useUserService.removeUser();
      navigate("/deleted-account");
    } catch (err) {
      alert(err);
    }
  };

  const changeMessageExpectant = async () => {
    setIsSelected(!isSelected);

    await useUserService.changeExpectation(!isSelected);
  };

  return (
    <div>
      <h1 className="text-3xl mt-4 mb-2">Settings</h1>
      <Card>
        <div className="p-4">
          <Checkbox
            defaultSelected
            color="primary"
            isSelected={isSelected}
            onValueChange={changeMessageExpectant}
          >
            Receive email notifications for SMS
          </Checkbox>
        </div>
      </Card>
      <Card className="w-full mb-4 mt-4">
        <CardHeader>
          <h2 className="text-2xl mt-4">Recover Password</h2>
        </CardHeader>
        <Divider />
        <CardBody>
          <Link href="/forgot-password" color="primary">
            <p>
              Recover your password if you think you might have forgotten it and
              may have trouble logging in next time
            </p>
          </Link>
        </CardBody>
      </Card>

      <Card className="w-full mb-4">
        <CardHeader>
          <h2 className="text-2xl mt-4">Cancel Subscription</h2>
        </CardHeader>
        <Divider />
        <CardBody>
          <Link
            href=""
            onPress={() => {
              setDeleteOrCancel("cancel");
              onOpen();
            }}
            color="danger"
          >
            <p>
              Cancel upcoming payments for your phone number. You will lose
              access to your phone number
            </p>
          </Link>
        </CardBody>
      </Card>

      <Card className="w-full mb-4">
        <CardHeader>
          <h2 className="text-2xl mt-4">Delete Account</h2>
        </CardHeader>
        <Divider />
        <CardBody>
          <Link
            href=""
            onPress={() => {
              setDeleteOrCancel("delete");
              onOpen();
            }}
            color="danger"
          >
            <p>Get rid of your GetSMS account completely.</p>
          </Link>
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={false}>
        <ModalContent>
          {deleteOrCancel === "delete"
            ? (onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    Caution!
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      If you delete your account, all your data will be gone
                      forever. Are you sure you want to proceed?
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" variant="light" onPress={onClose}>
                      Close
                    </Button>
                    <Button
                      color="danger"
                      onPress={() => {
                        setDeleteOrCancel("confirmDelete");
                        onOpen();
                      }}
                    >
                      Delete account
                    </Button>
                  </ModalFooter>
                </>
              )
            : deleteOrCancel === "confirmDelete"
            ? (onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    Caution!
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      Are you absolutely sure you want to delete your account?
                      Once it’s gone there’s no getting it back, along with all
                      your data. If you’re positive about this decision, click
                      the button below.
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" variant="light" onPress={onClose}>
                      Close
                    </Button>
                    <Button color="danger" onPress={deleteAccount}>
                      Confirm delete
                    </Button>
                  </ModalFooter>
                </>
              )
            : (onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    Caution!
                  </ModalHeader>
                  <ModalBody>
                    <p>Are you sure you want to cancel the subscription?</p>
                    <p>
                      You may lose your phone number if you don't resubscribe
                      until next payment.
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" variant="light" onPress={onClose}>
                      Close
                    </Button>
                    <Button color="danger" onPress={cancelSubscription}>
                      Confirm subscription canceling
                    </Button>
                  </ModalFooter>
                </>
              )}
        </ModalContent>
      </Modal>
    </div>
  );
};

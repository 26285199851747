import { useFeatureFlagVariantKey, usePostHog } from "posthog-js/react";

const featureFlagKey = "pricing-test";
const promoKey = "promo";

export const usePrice = function (
  promo?: string
): [price: number, testPriceKey: string] {
  const posthog = usePostHog();
  const testPriceKey = useFeatureFlagVariantKey(featureFlagKey) as string;

  const { REACT_APP_PRICING_TEST, REACT_APP_SUBSCRIPTION_PRICE } = process.env;

  const decodedPriceValue = JSON.parse(REACT_APP_PRICING_TEST as string) || {};

  let price: number = Number(REACT_APP_SUBSCRIPTION_PRICE);

  const keyInLocalStorage = localStorage.getItem(promoKey) || "";
  if (keyInLocalStorage) {
    price = Number(decodedPriceValue[keyInLocalStorage as any]) || price;

    posthog.featureFlags.override({ featureFlagKey: keyInLocalStorage });

    return [price, keyInLocalStorage];
  }
  
  if (promo) {
    const promoNumberRegex = /\d/;
    const priceKey = promo.match(promoNumberRegex) || [];
    const key = priceKey[0] || String(price);

    posthog.featureFlags.override({ featureFlagKey: key });

    price = Number(decodedPriceValue[key as any]) || price;

    localStorage.setItem(promoKey, key);

    return [price, key];
  }

 

  price = Number(decodedPriceValue[testPriceKey as any]) || price;

  return [price, testPriceKey];
};

import { Card, Accordion, AccordionItem } from "@nextui-org/react";

export const FAQ = () => {
  return (
    <>
      <h1 className="text-7xl">FAQ</h1>
      <Card className="p-5 mt-10">
        <Accordion>
          <AccordionItem
            key="0"
            aria-label="Do you need my passport information or any way to verify me?"
            title="Do you need my passport information or any way to verify me?"
          >
            Short answer is NO! We don't ask for your passport information or
            any other personal data!
          </AccordionItem>
          <AccordionItem
            key="1"
            aria-label="What is a virtual number?"
            title="What is a virtual number?"
          >
            A virtual number is a phone number not tied to a physical device. It
            operates in the cloud and can be routed to any existing phone line
            or device. Businesses use them for professional communication,
            individuals for privacy.
          </AccordionItem>
          <AccordionItem
            key="2"
            aria-label="Where can I use my virtual mobile numbers?"
            title="Where can I use my virtual mobile numbers?"
          >
            You can use virtual mobile numbers for business communication,
            online accounts verification, privacy protection, international
            communication, temporary projects, and call forwarding.
          </AccordionItem>
          <AccordionItem
            key="3"
            aria-label="Will someone access my number when I stop renting it?"
            title="Will someone access my number when I stop renting it?"
          >
            We delete all data associated with the phone number upon termination
            of rental. However, it's important to note that sites or services
            accessed using that number may still be accessible with it. For your
            security, we advise deleting all accounts associated with the number
            to prevent unauthorized access.{" "}
          </AccordionItem>
          <AccordionItem
            key="4"
            aria-label="What happens if I cannot receive SMS?"
            title="What happens if I cannot receive SMS?"
          >
            If you encounter difficulties receiving SMS messages, don't worry.
            You can contact us at hello@get-sms.app, and our support team will
            assist you in resolving the issue promptly. We're here to help and
            tackle any challenges you may face with receiving SMS messages.
          </AccordionItem>
        </Accordion>
      </Card>
    </>
  );
};
